<script>
/* eslint-disable  */
</script>
<template>
  <div class="control-section">
    <div align="center">
      <ejs-chart
        :theme="theme"
        align="center"
        id="chartcontainer-incasso-nel-tempo"
        :title="title"
        :primaryXAxis="primaryXAxis"
        :primaryYAxis="primaryYAxis"
        :chartArea="chartArea"
        :width="width"
        :tooltip="tooltip"
        :load="load"
        :palettes="palettes"
      >
        <e-series-collection>
          <e-series
            :dataSource="venditeNelTempo"
            type="Column"
            xName="x"
            yName="y"
            :name="nameX"
            width="1"
            :marker="marker"
          >
          </e-series>
        </e-series-collection>
      </ejs-chart>
    </div>
  </div>
</template>
<style scoped>
</style>
<script>
import Vue from "vue";
import { Browser } from "@syncfusion/ej2-base";
import {
  ChartPlugin,
  ColumnSeries,
  Category,
  DataLabel,
  Tooltip,
  Legend,
} from "@syncfusion/ej2-vue-charts";

Vue.use(ChartPlugin);

let selectedTheme = location.hash.split("/")[1];
selectedTheme = selectedTheme ? selectedTheme : "Material";
let theme = (
  selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)
).replace(/-dark/i, "Dark");

export default Vue.extend({
  props: {
    venditeNelTempo: {
      type: Array,
      required: true,
    },
  },
  data: function () {
    return {
      theme: theme,
      palettes: ['#ff9f43'],
      seriesData: [
        /* { x: "USA", y: 46 },
        { x: "GBR", y: 27 },
        { x: "qqq", y: 26 },
        { x: "ciao", y: 37 },
        { x: "aaa", y: 23 },
        { x: "zzz", y: 18 },
        { x: "bbb", y: 38 },
        { x: "eeee", y: 17 },
        { x: "mmm", y: 26 },
        { x: "dasdsa", y: 38 },
        { x: "we2", y: 17 },
        { x: "mmma", y: 26 },
        { x: "dasdadada", y: 38 },
        { x: "31231eee", y: 17 },
        { x: "343", y: 26 },
        { x: "aaacasd", y: 38 },
        { x: "13123", y: 17 },
        { x: "12321", y: 26 }, */
      ],

      seriesData1: [
        { x: "USA", y: 37 },
        { x: "GBR", y: 23 },
        { x: "CHN", y: 18 },
      ],

      seriesData2: [
        { x: "USA", y: 38 },
        { x: "GBR", y: 17 },
        { x: "CHN", y: 26 },
      ],

      //Initializing Primary X Axis
      primaryXAxis: {
        valueType: "Category",
        interval: 1,
        majorGridLines: { width: 0 },
        labelIntersectAction: 'Rotate45',
      },
      chartArea: { border: { width: 0 } },

      //Initializing Primary Y Axis
      primaryYAxis: {
        majorGridLines: { width: 0 },
        majorTickLines: { width: 0 },
        lineStyle: { width: 0 },
        labelStyle: { color: "transparent" },
      },

      width: Browser.isDevice ? "100%" : "100%",
      marker: {
        dataLabel: {
          visible: true,
          position: "Top",
          font: { fontWeight: "600", color: "#ffffff" },
        },
      },

      tooltip: {
        enable: true,
      },

      title: "Incasso(€)",
      nameX: 'Tempo',
    };
  },
  provide: {
    chart: [ColumnSeries, Legend, DataLabel, Category, Tooltip],
  },
  methods: {
    load: function (args) {
      let selectedTheme = location.hash.split("/")[1];
      selectedTheme = selectedTheme ? selectedTheme : "Material";
      if (selectedTheme === "highcontrast") {
        args.chart.series[0].marker.dataLabel.font.color = "#000000";
        args.chart.series[1].marker.dataLabel.font.color = "#000000";
        args.chart.series[2].marker.dataLabel.font.color = "#000000";
      }
    },
  },
});
</script>