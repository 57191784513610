<script>
/* eslint-disable */

</script>
<template>
  <b-modal
    id="modal-battute-bilance"
    ref="modal-battute-bilance"
    centered
    size="xl"
    title="Battute Bilance"
    hide-footer
    :visible="show"
    no-close-on-backdrop
    @hidden="show = false"
  >
    <ejs-toast
      id="toast_type"
      ref="toastRef"
      :position="{ X: 'Right' }"
    />
    <b-row>
      <b-col
        cols="6"
        md="5"
        lg="4"
        xl="3"
      >
        <b-form-group
          :label="'Data Battute'"
          label-for="date-battute"
          label-class="font-weight-bolder"
        >
          <flatPickr
            id="date-battute"
            v-model="rangeDateBattute"
            :config="configFlatPicker"
            :placeholder="'data battute'"
            style="width: 100%"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <!-- :childGrid="childGrid" 
    :detail-template="detailTemplate"
    -->
    <ejs-grid
      id="overviewgrid-show-battute"
      ref="overviewgrid-show-battute"
      locale="it-IT"
      :data-source="data"
      :detail-template="detailTemplate"
      :edit-settings="editSettings"
      :allow-filtering="true"
      :filter-settings="filter"
      :show-column-chooser="true"
      :allow-selection="true"
      :allow-sorting="true"
      :allow-resizing="true"
      :enable-virtualization="false"
      :allow-excel-export="true"
      height="400"
      row-height="38"
      :enable-hover="false"
      :enable-header-focus="true"
      :toolbar="toolbar"
      :allow-grouping="false"
      :allow-paging="true"
      :page-settings="pageSettings"
      :toolbar-click="toolbarClick"
      :queryCellInfo="customiseCell"
      :context-menu-items="contextMenuItems"
    >
      <e-columns>
        <e-column
          field="id"
          header-text="id"
          width="20"
          :is-primary-key="true"
          :visible="false"
        />
        <e-column
          field="ReceiptID"
          header-text="Codice Scontrino"
          :filter="filter"
          width="120"
        />
        <e-column
          field="annullata"
          header-text="Annullato"
          :filter="filter"
          width="50"
        />
        <e-column
          field="ReceiptDate"
          header-text="Data"
          width="120"
        />
        <e-column
          field="Weight"
          header-text="Peso[kg]"
          width="120"
        />
        <e-column
          field="PriceToPay"
          header-text="Prezzo[€]"
          width="120"
        />
      </e-columns>
    </ejs-grid>
  </b-modal>
</template>

<script>
// import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Vue from 'vue'
import {
  DetailRow, GridPlugin, Sort, Filter, Selection, Toolbar, Edit, Page, ContextMenu, Resize, Scroll, Aggregate, ExcelExport,
} from '@syncfusion/ej2-vue-grids'
import { ToastPlugin } from '@syncfusion/ej2-vue-notifications'
// import { extend } from '@syncfusion/ej2-base';
import { L10n, setCulture } from '@syncfusion/ej2-base'
import {
  BModal, BFormGroup, BRow, BCol, BTable,
} from 'bootstrap-vue'
import moment from 'moment'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Italian } from 'flatpickr/dist/l10n/it'
import { italian } from '@/@core/grid-translation/it-IT'
import { getBattute } from '@/@core/api-service/devices/jobBattute'

Vue.use(GridPlugin)
Vue.use(ToastPlugin)
setCulture('it-IT')
L10n.load(italian)

export default {
  components: {
    BModal,
    flatPickr,
    BFormGroup,
    BRow,
    BCol,
    // ValidationProvider,
    // ValidationObserver,
    // vSelect,
  },
  data() {
    return {
      data: [
        
      ],
      rangeDateBattute: 'Ultime 24h',
      show: false,
      pageSettings: { pageSize: 25, pageSizes: [25, 50, 100, 200], pageCount: 5 },
      editSettings: {
        showDeleteConfirmDialog: false, allowEditing: false, allowAdding: false, allowDeleting: false, mode: 'Normal',
      },
      filter: {
        type: 'CheckBox',
      },
      battuteRige: [],
      toolbar: ['Print', 'ExcelExport', 'CsvExport', 'Search'],// 'PdfExport'
      contextMenuItems: ['AutoFit', 'AutoFitAll', 'SortAscending', 'SortDescending',
        'Copy', 'Edit', 'Delete', 'Save', 'Cancel', 'FirstPage', 'PrevPage',
        'LastPage', 'NextPage', 'Group', 'Ungroup', {
          text: 'Dettagli', target: '.e-content', id: 'Dettagli', iconCss: 'e-icons e-folder-open',
        }],
        childGrid: {
          dataSource: [],
          queryString: 'ReceiptID',
          columns: [
              { field: 'ReceiptID', headerText: 'cod', textAlign: 'Right', width: 120 },
              { field: 'annullata', headerText: 'annullato', width: 150 },
              { field: 'TradeDate', headerText: 'data', width: 150 },
              { field: 'Description', headerText: 'nome', width: 150 },
              { field: 'Weight', headerText: 'Peso[kg]', width: 150 },
              { field: 'BasePrice', headerText: 'prezzo[kg]', width: 150 },
              { field: 'PriceToPay', headerText: 'totale', width: 150 },
          ],
        },
      detailTemplate() {
        return {
          template: Vue.component('detailTemplate', {
            components: {
              BRow,
              BCol,
              BTable,
            },
            data() {
              return {
                data: {},
              }
            },
            computed: {
              battute() {
                return this.data.battutaRiga.map(el => ({
                  'cod.': el.ReceiptID, annullato: el.annullata, data: el.TradeDate, nome: el.Description, 'Peso[kg]': el.Weight, 'prezzo[kg]': el.BasePrice, totale: el.PriceToPay,
                }))
              },
            },
            template: `
              <div>
                <b-table responsive="sm" :items="battute" />
              </div>
            `,
          }),
        }
      },
      configFlatPicker: {
        mode: 'range',
        wrap: false, // set wrap to true only when using 'input-group'
        altFormat: 'Y-m-d',
        altInput: true,
        dateFormat: 'Y-m-d',
        locale: Italian, // locale for this instance only
        onChange: selectedDates => {
          // console.log(selectedDates)
          if (selectedDates.length > 1) {
            const fromYear = String(selectedDates[0].getFullYear())
            const fromMonth = String(selectedDates[0].getMonth() + 1)
            const fromDays = String(selectedDates[0].getDate())
            const toYear = String(selectedDates[1].getFullYear())
            const toMonth = String(selectedDates[1].getMonth() + 1)
            const toDays = String(selectedDates[1].getDate())
            const fullDateFrom = new Date(fromYear.concat('-', fromMonth, '-', fromDays))
            const fullDateTo = new Date(toYear.concat('-', toMonth, '-', toDays))
            /* console.log('fromYear', fromYear)
            console.log('fromMonth', fromMonth)
            console.log('fromDays', fromDays)
            console.log('toYear', toYear)
            console.log('toMonth', toMonth)
            console.log('toDays', toDays) */
            fullDateFrom.setHours(fullDateFrom.getHours() + 0)
            fullDateTo.setHours(fullDateTo.getHours() + 23)
            fullDateTo.setMinutes(fullDateTo.getMinutes() + 59)
            let fullTo1 = new Date(fullDateTo - (new Date().getTimezoneOffset() * 60 * 1000)).toISOString().slice(0, -5).split('T')
            let fullDateFrom1 = new Date(fullDateFrom - (new Date().getTimezoneOffset() * 60 * 1000)).toISOString().slice(0, -5).split('T')
            fullTo1 = String(fullTo1[0].concat(' ', fullTo1[1]))
            fullDateFrom1 = String(fullDateFrom1[0].concat(' ', fullDateFrom1[1]))
            // console.log(fullDateFrom1, fullTo1)
            this.getScontriniServer({ dataInizio: fullDateFrom1, dataFine: fullTo1, idPuntoVendita: Number(this.$route.params.id) })
          }
        },
      },
    }
  },
  computed: {

  },
  mounted() {
    this.getScontriniServer({ dataInizio: moment().format('YYYY-MM-DD'), dataFine: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'), idPuntoVendita: Number(this.$route.params.id) })
  },
  methods: {
    openModal() {
      this.show = true
    },
    hideModal() {
      this.show = false
    },
    resetModal() {
      // this.typeAddressSelected = ''
    },
    toolbarClick(args) {
      // console.log(args)
      if (args.item.id === 'overviewgrid-show-battute_pdfexport') { // 'Grid_pdfexport' -> Grid component id + _ + toolbar item name
          // this.showModalSettingPdfExport = true
            const exportProperties = {
               // hierarchyExportMode: "Expanded",
                //you can customize the name as per your requirement
                fileName: `BattuteScontrini_${this.data[0].ReceiptDate + '-' + this.data[this.data.length - 1].ReceiptDate}.pdf`,
             }
            // this.$bvModal.show('setting-pdf-export-modal')
            // this.$refs['overviewgrid-show-battute'].pdfExport(exportProperties)
            // this.$refs.overviewgrid.pdfExport(exportProperties)
        }
        if (args.item.id === 'overviewgrid-show-battute_excelexport') { // 'Grid_excelexport' -> Grid component id + _ + toolbar item name
            const exportProperties = {
                //you can customize the name as per your requirement
                fileName: `BattuteScontrini_${this.data[0].ReceiptDate + '-' + this.data[this.data.length - 1].ReceiptDate}.xlsx`,
             }
            this.$refs['overviewgrid-show-battute'].excelExport(exportProperties)
        }
        if (args.item.id === 'overviewgrid-show-battute_csvexport') { // 'Grid_excelexport' -> Grid component id + _ + toolbar item name
            const exportProperties = {
                //you can customize the name as per your requirement
                fileName: `BattuteScontrini_${this.data[0].ReceiptDate + '-' + this.data[this.data.length - 1].ReceiptDate}.csv`,
             }
            this.$refs['overviewgrid-show-battute'].csvExport(exportProperties)
        }
    },
    getScontriniServer(dateRange) {
      const dayOfYearEnd = moment(dateRange.dataFine).dayOfYear()
      const dayOfYearStart = moment(dateRange.dataInizio).dayOfYear()
      /* console.log('dayOfYearEnd', dayOfYearEnd)
      console.log('dayOfYearStart', dayOfYearStart)
      console.log('diff', dayOfYearEnd - dayOfYearStart) */
      if (dayOfYearEnd - dayOfYearStart <= 20) {
        getBattute(dateRange).then(res => {
          if (res.data.esito === 'OK') {
            this.data = [...res.data.scontrini.map(el => ({ ...el, ReceiptID: el.ReceiptID}))]
            // this.data.forEach(el => this.battuteRige.push(...el.battutaRiga.map(el => ({ ...el, ReceiptID: Number(el.ReceiptID)}))))

            // console.log(this.data)
           //  console.log(this.childGrid.dataSource)
            
          }
        })
      } else {
        this.$refs.toastRef.show({
          title: 'Arco Temporale troppo lungo', content: 'Selezionare un arco temporale massimo di 20 giorni', cssClass: 'e-toast-danger', icon: 'e-error toast-icons',
        })
      }
    },
    customiseCell(args) {
      if (args.data.annullata === 'SI') {
        args.cell.classList.add('battutaRigaAnnullata')
      }
    },
  },
  provide: {
    grid: [DetailRow, Toolbar, Filter, Selection, Sort, Edit, Page, ContextMenu, Resize, Scroll, Aggregate, ExcelExport],
  },
}
</script>
<style>
.battutaRigaAnnullata {
  color: red !important;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
