<script>
/* eslint-disable  */
</script>
<template>
  <div>
    <ejs-toast
      id="toast_type"
      ref="toastRef"
      :position="{ X: 'Right' }"
    />
    <b-card>
      <b-row>
        <b-col>
          <div>
            <b-media no-body>
              <b-media-aside class="d-flex flex-column">
                <b-link>
                  <b-img
                    ref="previewEl"
                    :src="require('@core/assets/iconpro/store.svg')"
                    rounded
                    height="80"
                  />
                </b-link>
              </b-media-aside>

              <b-media-body class="mt-75 ml-75">
                <b-card-text>
                  <ul class="list-unstyled">
                    <li><h3><strong>{{ organizationComputed[0].nome }}</strong></h3></li>
                    <li><strong>Indirizzo: </strong><span>{{ organizationComputed[0].indirizzo }}</span></li>
                    <li><strong>Recapito: </strong><span>{{ organizationComputed[0].telefono }}</span></li>
                    <li><strong>Email: </strong><span>{{ organizationComputed[0].email }}</span></li>
                    <li><strong>Responsabile: </strong><span>{{ organizationComputed[0].responsabile }}</span></li>
                  </ul>
                </b-card-text>
              </b-media-body>
            </b-media>
            <!-- <div>
              <h4>organization</h4>
              {{ organizationComputed }}
            </div> -->
          </div>
        </b-col>
        <!-- <b-col>
          <ul class="list-unstyled">
            <li><h3><strong>Team</strong></h3></li>
            <li><b-avatar-group size="32px">
              <b-avatar
                v-b-tooltip.hover
                class="pull-up"
                title="Jenny Looper"
                :src="require('@/assets/images/portrait/small/avatar-s-5.jpg')"
              />
              <b-avatar
                v-b-tooltip.hover
                class="pull-up"
                title="Darcey Nooner"
                variant="primary"
                :src="require('@/assets/images/portrait/small/avatar-s-7.jpg')"
              />
              <b-avatar
                v-b-tooltip.hover
                class="pull-up"
                title="Julee Rossignol"
                :src="require('@/assets/images/portrait/small/avatar-s-10.jpg')"
              />
              <b-avatar
                v-b-tooltip.hover
                class="pull-up"
                title="Elicia Rieske"
                variant="danger"
                :src="require('@/assets/images/portrait/small/avatar-s-8.jpg')"
              />
              <b-avatar
                v-b-tooltip.hover
                class="pull-up"
                title="Vinnie Mostowy"
                :src="require('@/assets/images/portrait/small/avatar-s-20.jpg')"
              />
            </b-avatar-group></li>
          </ul>
        </b-col>
        <b-col>
          <ul class="list-unstyled">
            <li><h3><strong>Dispositivi</strong></h3></li>
            <li><b-link><strong>Bilancia 1</strong></b-link></li>
            <li><b-link><strong>Bilancia 2</strong></b-link></li>
            <li><b-link><strong>Conta Persone</strong></b-link></li>
          </ul>
        </b-col>
        <b-col>
          <ul class="list-unstyled">
            <li><h3><strong>Azioni</strong></h3></li>
            <li><b-link
              :to="{ name: 'organization-configuration', params: { id: this.$route.params.id }}"
            >
              <strong>Configurazione Punto Vendita</strong>
            </b-link></li>
            <li>
              <b-link :to="{ name: 'multiple-ddt-single-store', params: { store: this.$route.params.id }}">
                <strong>Arrivo Merce DDT</strong>
              </b-link>
            </li>
            <li>
              <b-link :to="{ name: 'load-quantity-shop-single', params: { store: this.$route.params.id }}">
                <strong>Carico/Scarico Merce</strong>
              </b-link>
            </li>
            <li>
              <b-link :to="{ name: 'load-price-plu-shop', params: { shop: this.$route.params.id }}">
                <strong>Impostaizoni Prezzi</strong>
              </b-link>
            </li>
          </ul>
        </b-col> -->
        <b-col>
          <ul class="list-unstyled">
            <li><h3><strong>Azioni</strong></h3></li>
            <!-- <li><b-link
              :to="{ name: 'organization-configuration', params: { id: this.$route.params.id }}"
            >
              <strong>Configurazione Punto Vendita</strong>
            </b-link></li> -->
            <!-- <li>
              <b-link :to="{ name: 'multiple-ddt-single-store', params: { store: this.$route.params.id }}">
                <strong>Arrivo Merce DDT</strong>
              </b-link>
            </li> -->
            <li>
              <b-link :to="{ name: 'multiple-ddt'}">
                <strong>Carica DDT</strong>
              </b-link>
            </li>
            <li>
              <b-link :to="{ name: 'quantity-warehouse-shop', params: { shop: this.$route.params.id }}">
                <strong>Situazione Magazzino</strong>
              </b-link>
            </li>
            <li>
              <b-link :to="{ name: 'flow-warehouse-shop', params: { shop: this.$route.params.id }}">
                <strong>Movimentazione Magazzino</strong>
              </b-link>
            </li>
            <li>
              <b-link
                :to="{ name: 'load-price-plu-shop', params: { shop: this.$route.params.id }}">
                <strong>Invio prezzi alle bilance</strong>
              </b-link>
            </li>
          </ul>
        </b-col>
      </b-row>
    </b-card>
    <validation-observer ref="observer-validate-form-stats">
      <b-row
        class="mb-2"
      >
        <b-col cols="12">
          <hr class="my-1">
        </b-col>
        <b-col
          md="6"
        >
          <b-form-group
              :label="'Data: '.concat(String(dataInizio).split(' ')[0],' - ',  String(String(dataFine).split(' ')[0]))"
              label-class="font-weight-bolder"
              class="d-inline mr-2"
            >
            <validation-provider
              #default="{ errors }"
              name="data"
              rules="required"
            >
              <flat-pickr
                v-model="rangeDate"
                class="form-control"
                :config="configFlatPicker"
                placeholder="Seleziona Date"
              />
              <small class="text-danger mt-25" style="position: absolute">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <div class="d-inline-block my-2 my-md-0">
            <b-form-checkbox
              v-model="tuttiProdotti"
              :value="true"
              :uncheck-value="false"
              class="my-md-3"
            >
              <strong>Tutti Prodotti</strong>
            </b-form-checkbox>
          </div>
        </b-col>
        <b-col>
          <b-form-group
              label="Seleziona Prodotti"
              label-class="font-weight-bolder"
              class=""
            >
              <validation-provider
                #default="{ errors }"
                name="Prodotti"
                :rules="tuttiProdotti !== true ? 'required' : ''"
              >
                <v-select
                  v-model="prodotti"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :clearable="true"
                  filterable
                  multiple
                  :disabled="tuttiProdotti"
                  label="nome"
                  placeholder="Prodotti"
                  :reduce="valore => ({ id: valore.id})"
                  :options="prodottiOption"
                  @open="getProducts"
                  @input="limiterSelection"
                >
                  <!-- eslint-disable-next-line vue/no-unused-vars  -->
                  <template #no-options="{ search, searching, loading }">
                    Nessuna Opzione disponibile.
                  </template>
                </v-select>
                <small class="text-danger mt-25" style="position: absolute">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
        </b-col>
        <b-col 
          cols="12"
          class="d-flex flex-row-reverse"
        >
          <b-button 
            variant="primary"
            class="align-self-end"
            @click="analizeStats"
          >
            Analizza
          </b-button>
        </b-col>
        <b-col cols="12">
          <hr class="my-1">
        </b-col>
      </b-row>
    </validation-observer>
    <stats-numeriche-organization
      :statisticheNumeriche="statisticheNumeriche"
    />
    <b-row>
      <b-col
        xl="6"
      >
        <b-card>
          <HystogramPiuVenduti
            :piuVenduti="piuVenduti"
          />
        </b-card>
      </b-col>
      <b-col
        xl="6"
      >
        <b-card>
          <PieChartIncassoMaggioreIncasso :dataMaggioreIncasso="piuVendutiTorta" />
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col
      >
        <b-card>
          <HystogramIncassoNelTempo
            :venditeNelTempo="venditeNelTempo"
          />
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        xl="6"
      >
        <b-card>
          <HystogramMaggioreIncasso
            :maggioreIncasso="maggioreIncasso"
          />
        </b-card>
      </b-col>
      <b-col
        xl="6"
      >
        <b-card>
          <PieChartIncassoNelTempo :dataIncassoNelTempo="maggioreIncassoTorta" />
        </b-card>
      </b-col>
      <b-col
        xl="12"
      >
        <b-card>
          <HystogramRimanenzeKg :rimanenzeMagazzino="rimanenzeMagazzino" />
        </b-card>
      </b-col>

    </b-row>
  </div>
</template>

<script>
import Vue from 'vue'
import {
  BRow, BCol, BCard, BMediaAside, BMedia, BLink, BCardText, BImg, BAvatar, BMediaBody, BAvatarGroup, VBTooltip, BFormGroup, BFormCheckbox, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import { ToastPlugin } from '@syncfusion/ej2-vue-notifications'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import moment from 'moment'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Italian } from 'flatpickr/dist/l10n/it'
import { getPuntoVendita, getPuntiVenditaStatistiche } from '@/@core/api-service/logistic/puntiVendita'
import visitorsByTypeTempalte from '../shopStats/linetemplate.vue'
import mapTemplate from '../shopStats/map.vue'
import useageStatisticsTemplate from '../shopStats/useage.vue'
import activeVisitorTemplate from '../shopStats/activeVisitors.vue'
import splineTemplate from '../shopStats/splinetemplate.vue'
import StatsNumericheOrganization from './components/StatsNumericheOrganization.vue'
import HystogramIncassoNelTempo from '../shopStats/HystogramIncassoNelTempo.vue'
import HystogramPiuVenduti from '../shopStats/HystogramPiuVenduti.vue'
import HystogramMaggioreIncasso from '../shopStats/HystogramMaggioreIncasso.vue'
import PieChartIncassoNelTempo from '../shopStats/PieChartQuantitaVenduta.vue'
import PieChartIncassoMaggioreIncasso from '../shopStats/PieChartIncassoMaggioreIncasso.vue'
import HystogramRimanenzeKg from '../shopStats/HystogramRimanenzeKg.vue'
import { getProdotti } from '@/@core/api-service/catalog/products'

Vue.use(ToastPlugin)

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BCard,
    BMediaAside,
    BMedia,
    BLink,
    BCardText,
    BImg,
    BAvatar,
    BMediaBody,
    BAvatarGroup,
    BFormGroup,
    BFormCheckbox,
    BButton,
    flatPickr,
    vSelect,
    StatsNumericheOrganization,
    HystogramIncassoNelTempo,
    HystogramPiuVenduti,
    HystogramMaggioreIncasso,
    PieChartIncassoNelTempo,
    PieChartIncassoMaggioreIncasso,
    HystogramRimanenzeKg,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,

  },
  data() {
    return {
      required,
      dataInizio: moment().format('YYYY-MM-DD'),
      dataFine: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
      locale: 'it',
      prodotti: [],
      prodottiOption: [],
      piuVenduti: [],
      maggioreIncasso: [],
      piuVendutiTorta: [],
      maggioreIncassoTorta: [],
      rimanenzeMagazzino: [],
      venditeNelTempo: [
        /* { x: 'USA', y: 46 },
        { x: 'GBR', y: 27 },
        { x: 'qqq', y: 26 },
        { x: 'ciao', y: 37 },
        { x: 'aaa', y: 23 },
        { x: 'zzz', y: 18 },
        { x: 'bbb', y: 38 },
        { x: 'eeee', y: 17 },
        { x: 'mmm', y: 26 },
        { x: 'dasdsa', y: 38 },
        { x: 'we2', y: 17 },
        { x: 'mmma', y: 26 },
        { x: 'dasdadada', y: 38 },
        { x: '31231eee', y: 17 },
        { x: '343', y: 26 },
        { x: 'aaacasd', y: 38 },
        { x: '13123', y: 17 },
        { x: '12321', y: 26 }, */
      ],
      tuttiProdotti: true,
      organization: [],
      palette: [
        '#ff0000',
        '#00ff00',
        '#0000ff',
        '#eeeeee',
        '#000000',
        '#4deeea',
        '#74ee15',
        '#ffe700',
        '#f000ff',
        '#001eff',
      ],
      spline() {
        return { template: splineTemplate }
      },
      line() {
        return { template: visitorsByTypeTempalte }
      },
      visitor() {
        return { template: activeVisitorTemplate }
      },
      usage() {
        return { template: useageStatisticsTemplate }
      },
      map() {
        return { template: mapTemplate }
      },
      // eslint-disable-next-line global-require
      img: require('@/assets/images/prodottiVET/croccantini.jpg'),
      imageShop: null,
      statisticheNumeriche: {},
      rangeDate: 'Ultime 24h',
      configFlatPicker: {
        wrap: false, // set wrap to true only when using 'input-group'
        altFormat: 'Y-m-d',
        altInput: true,
        dateFormat: 'Y-m-d',
        locale: Italian, // locale for this instance only
        mode: 'range',
        onChange: selectedDates => {
          // console.log(selectedDates)
          if (selectedDates.length > 1) {
            const fromYear = String(selectedDates[0].getFullYear())
            const fromMonth = String(selectedDates[0].getMonth() + 1)
            const fromDays = String(selectedDates[0].getDate())
            const toYear = String(selectedDates[1].getFullYear())
            const toMonth = String(selectedDates[1].getMonth() + 1)
            const toDays = String(selectedDates[1].getDate())
            const fullDateFrom = new Date(fromYear.concat('-', fromMonth, '-', fromDays))
            const fullDateTo = new Date(toYear.concat('-', toMonth, '-', toDays))
            /* console.log('fromYear', fromYear)
            console.log('fromMonth', fromMonth)
            console.log('fromDays', fromDays)
            console.log('toYear', toYear)
            console.log('toMonth', toMonth)
            console.log('toDays', toDays) */
            fullDateFrom.setHours(fullDateFrom.getHours() + 0)
            fullDateTo.setHours(fullDateTo.getHours() + 23)
            fullDateTo.setMinutes(fullDateTo.getMinutes() + 59)
            // console.log('fullDateTo', fullDateTo)
            let fullTo1 = new Date(fullDateTo - (new Date().getTimezoneOffset() * 60 * 1000)).toISOString().slice(0, -5).split('T')
            let fullDateFrom1 = new Date(fullDateFrom - (new Date().getTimezoneOffset() * 60 * 1000)).toISOString().slice(0, -5).split('T')
            fullTo1 = String(fullTo1[0].concat(' ', fullTo1[1]))
            fullDateFrom1 = String(fullDateFrom1[0].concat(' ', fullDateFrom1[1]))
            // this.rangeDate = [fullDateFrom1, fullTo1]
            /* console.log('fullTo1', fullTo1)
            console.log('fullDateFrom1', fullDateFrom1) */
            this.dataInizio = fullDateFrom1
            this.dataFine = fullTo1
            // this.getStats(Number(this.$route.params.id), fullDateFrom1, fullTo1)
          }
        },
      },
    }
  },
  watch: {
    tuttiProdotti(next, prev) {
      /* console.log('next', next)
      console.log('prev', prev) */
      /* if(next) {
        this.prodotti = []
      } */
      // this.getStats(Number(this.$route.params.id), this.dataInizio, this.dataFine)
    },
  },
  computed: {
    organizationComputed() {
      return this.organization
    },
  },
  mounted() {
    localize(this.locale)
  },
  async created() {
    moment.updateLocale('it', {
      months: 'Gennaio_Febbraio_Marzo_Aprile_Maggio_Giugno_Luglio_Agosto_Settembre_Ottobre_Novembre_Dicembre'.split('_'),
      monthsShort: 'Genn_Feb_Mar_Apr_Mag_Giu_Lug_Ago_Set_Ott_nov_dic'.split('_'),
      monthsParseExact: true,
      weekdays: 'domenica_lunedi_martedi_mercoledi_giovedi_venerdi_sabato'.split('_'),
      weekdaysShort: 'dom_lun_mar_mer_gio_ven_sab'.split('_'),
    })
    const id = JSON.stringify([{ id: this.$route.params.id }])
    // const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
    await getPuntoVendita(id)
      .then(res => {
        if (res.data.esito === 'OK') {
          this.organization = [{ ...res.data.puntovendita }]
        }
      })
      .catch(e => e)
    // moment().format( 'DD/MM/YYYY HH:mm:ss').add(24, 'h')
    // console.log({ idPuntoVendita: Number(this.$route.params.id), dataInizio: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'), dataFine: moment(new Date()).add(1, 'd').format('YYYY-MM-DD HH:mm:ss') })
    // console.log({ idPuntoVendita: Number(this.$route.params.id), dataInizio: '2022-04-29 19:07:01', dataFine: '2022-04-29 19:17:48' })
    // console.log({ idPuntoVendita: Number(this.$route.params.id), dataInizio: '2020-04-29 19:07:01', dataFine: '2023-04-29 19:17:48' })
    // console.log(moment().format('YYYY-MM-DD'), moment().add(1, 'd').format('YYYY-MM-DD'))
    this.getStats(Number(this.$route.params.id), moment().format('YYYY-MM-DD'), moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'))
  },
  methods: {
    async getStats(idPuntoVendita, dataInizio, dataFine) {
      // console.log('dataInizio', dataInizio)
      // console.log('dataFine', dataFine)
      // console.log('idPuntoVendita', idPuntoVendita)
      // console.log({ idPuntoVendita, dataInizio, dataFine, tuttiProdotti: this.tuttiProdotti, prodotti: this.prodotti })
      await getPuntiVenditaStatistiche({ idPuntoVendita, dataInizio, dataFine, tuttiProdotti: this.tuttiProdotti, prodotti: this.prodotti }).then(res => {
        /// console.log(res)
        if (res.data.esito === 'OK') {
          this.statisticheNumeriche = {
            incasso: res.data.incasso,
            mediaBattute: res.data.mediaBattute,
            numeroBattute: res.data.numeroBattute,
            quantitaKg: res.data.quantitaVendutaKg,
            incassoKg: res.data.incassoKg,
            numeroScontrini: res.data.hasOwnProperty('numeroScontrini') ? res.data.numeroScontrini : undefined,
            mediaScontrini: res.data.hasOwnProperty('mediaScontrini') ? res.data.mediaScontrini : undefined,
            quantitaRimanente: res.data.quantitaRimanente,
          }
          const statsNelTempo = [...res.data.prodottiBattute]
          statsNelTempo.forEach((el, index) => {
            Vue.set(el, 'uniqueID', index)
          })
          statsNelTempo.sort((a,b) => {
            // console.log(moment(a.dataBattuta))
            // console.log(moment(b.dataBattuta))
            return moment(a.dataBattuta).format('YYYY-MM-DD') - moment(b.dataBattuta).format('YYYY-MM-DD')
          })   
          // scaglione in ore
          const arrayTemporaneo = []
          const arrayOggettiSommati = []
          if (moment(dataInizio).dayOfYear() === moment(dataFine).dayOfYear()) {
            // console.log('oreee')
            let ora = 0
            while(ora <= 23) {
              statsNelTempo.forEach(el => {
                if (moment(el.dataBattuta).hours() === ora) {
                  arrayTemporaneo.push(el)
                }
              })
              let oggettoTemp = {}
              let sommaPrezzo = 0
              if (arrayTemporaneo.length > 0) {
                const dataOggetto = moment(arrayTemporaneo[0].dataBattuta).format('YYYY-MM-DD')
                arrayTemporaneo.forEach(el => {
                  sommaPrezzo += Number(el.prezzo)
                })
                oggettoTemp = {
                  x: String(ora).concat(':00'),
                  y: sommaPrezzo.toFixed(2),
                }
                arrayOggettiSommati.push(oggettoTemp)
                arrayTemporaneo.length = 0
              }
              ora+=1
            }
            this.venditeNelTempo = [...arrayOggettiSommati]
            this.venditeNelTempo.sort((a,b) => {
              return new Date(a.x) - new Date(b.x)
            })           
          } else if (moment(dataInizio).year() === moment(dataFine).year() && moment(dataInizio).month() === moment(dataFine).month() && moment(dataInizio).dayOfYear() !== moment(dataFine).dayOfYear()) {
            // console.log('giorni')
            let giorni = 1
            while(giorni <= 32) {
              statsNelTempo.forEach((el, index) => {
                if (moment(el.dataBattuta).date() + 1 === giorni) {
                  arrayTemporaneo.push(el)
                }
              })
              let oggettoTemp = {}
              let sommaPrezzo = 0
              if (arrayTemporaneo.length > 0) {
                const dataOggetto = moment(arrayTemporaneo[0].dataBattuta).format('YYYY-MM-DD')
                arrayTemporaneo.forEach(el => {
                  sommaPrezzo += Number(el.prezzo)
                })
                oggettoTemp = {
                  x: dataOggetto,
                  y: sommaPrezzo.toFixed(2),
                }
                arrayOggettiSommati.push(oggettoTemp)
                arrayTemporaneo.length = 0
              }
              giorni+=1
            }
            this.venditeNelTempo = [...arrayOggettiSommati]
            this.venditeNelTempo.sort(function(a,b){
              return new Date(a.x) - new Date(b.x)
            })
            this.venditeNelTempo.forEach(el => {
              el.x = String(Number(moment(el.x).date())).concat(' ', String(moment(el.x).format('ddd')))
            })
            // scaglione in anni
          } else if (moment(dataInizio).year() === moment(dataFine).year() && moment(dataInizio).month() !== moment(dataFine).month()) {
            // console.log('in mesi')
            let mesi = 1
            while(mesi <= 11) {
              statsNelTempo.forEach(el => {
                if (moment(el.dataBattuta).month() + 1 === mesi) {
                  arrayTemporaneo.push(el)
                }
              })
              let oggettoTemp = {}
              let sommaPrezzo = 0
              if (arrayTemporaneo.length > 0) {
                const dataOggetto = moment(arrayTemporaneo[0].dataBattuta).format('YYYY-MM-DD')
                arrayTemporaneo.forEach(el => {
                  sommaPrezzo += Number(el.prezzo)
                })
                oggettoTemp = {
                  x: dataOggetto,
                  y: sommaPrezzo.toFixed(2),
                }
                arrayOggettiSommati.push(oggettoTemp)
                arrayTemporaneo.length = 0
              }
              mesi+=1
            }
            this.venditeNelTempo = [...arrayOggettiSommati]
            this.venditeNelTempo.sort(function(a,b){
              return new Date(a.x) - new Date(b.x)
            })
            this.venditeNelTempo.forEach(el => {
              el.x = String(moment(el.x).format('MMM'))
            })
            // scaglione in anni
          } else if (moment(dataInizio).year() !== moment(dataFine).year()) {
            // console.log('in anni')
            let anni = 2020
            while(anni <= 2030) {
              statsNelTempo.forEach(el => {
                if (moment(el.dataBattuta).year() === anni) {
                  arrayTemporaneo.push(el)
                }
              })
              let oggettoTemp = {}
              let sommaPrezzo = 0
              if (arrayTemporaneo.length > 0) {
                const dataOggetto = moment(arrayTemporaneo[0].dataBattuta).format('YYYY-MM-DD')
                arrayTemporaneo.forEach(el => {
                  sommaPrezzo += Number(el.prezzo)
                })
                oggettoTemp = {
                  x: dataOggetto,
                  y: sommaPrezzo.toFixed(2),
                }
                arrayOggettiSommati.push(oggettoTemp)
                arrayTemporaneo.length = 0
              }
              anni+=1
            }
            this.venditeNelTempo = [...arrayOggettiSommati]
            this.venditeNelTempo.sort(function(a,b){
              return new Date(a.x) - new Date(b.x)
            })
            this.venditeNelTempo.forEach(el => {
              el.x = String(moment(el.x).format('YYYY'))
            })
          }
        } else if (res.data.esito === 'KO') {
          this.statisticheNumeriche = {
            incasso: 0,
            mediaBattute: 0,
            numeroBattute: 0,
          }
          this.venditeNelTempo = []
          this.piuVenduti = []
          this.maggioreIncasso = []
          this.$refs.toastRef.show({
            title: 'Attenzione!', content: res.data.messaggio, cssClass: 'e-toast-info', icon: 'e-info toast-icons',
          })
        }
        this.piuVenduti = [...res.data.prodottiPiuVenduti].map(el => ({ x: el.nome, y: el.quantita })).slice(0, 10)
        this.maggioreIncasso = [...res.data.prodottiMaggioreIncasso].map(el => ({ x: el.nome, y: el.incasso })).slice(0, 10)
        const totalePiuVenduti = this.piuVenduti.reduce((a, b) => a + Number(b.y), 0)
        const totaleMaggioreIncasso = this.maggioreIncasso.reduce((a, b) => a + Number(b.y), 0)
        this.piuVendutiTorta = this.piuVenduti.map((el, index) => ({ x: el.x, y: Number((el.y * 100) / Number(totalePiuVenduti)).toFixed(2), fill: this.palette[index], text: el.x.toLowerCase() }))
        this.maggioreIncassoTorta = this.maggioreIncasso.map((el, index) => ({ x: el.x, y: Number((el.y * 100) / Number(totaleMaggioreIncasso)).toFixed(2), fill: this.palette[index], text: el.x.toLowerCase() }))
        this.rimanenzeMagazzino = []
        this.rimanenzeMagazzino = [...res.data.rimanenzeMagazzino].filter(el => el.tipoProdotto === 'UNIFICATO').map(el => ({ x: el.nome, y: el.quantita })).slice(0, 10)

      }).catch(e => e)
      // console.log(this.venditeNelTempo)
    },
    limiterSelection(e) {
      if (e.length > 10) {
        e.pop()
      }
      // this.getStats(Number(this.$route.params.id), this.dataInizio, this.dataFine)
    },
    analizeStats() {
      this.$refs['observer-validate-form-stats'].validate().then(success => {
        if (success) {
          this.getStats(Number(this.$route.params.id), this.dataInizio, this.dataFine)
        }
      })
    },
    getProducts() {
      getProdotti().then(res => {
        if (res.data.esito === 'OK') {
          this.prodottiOption = [...res.data.prodotti].filter(prodotto => prodotto.tipoProdotto === 'Unificato')
          // this.prodottiOption.unshift({ id: 0, nome: 'TUTTI I PRODOTTI'})
        }
      })
    },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
