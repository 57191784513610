<script>
/* eslint-disable  */
</script>
<template>
  <div>
    <ejs-accumulationchart
      id="organizzazione-pie-chart-incasso-nel-tempo"
      :title="title"
      :legendSettings="legendSettings"
      :tooltip="tooltip"
    >
      <e-accumulation-series-collection>
          <e-accumulation-series :dataSource="dataIncassoNelTempo" xName="x" yName="y" :dataLabel="datalabel" :pointColorMapping="pointColorMapping" />
      </e-accumulation-series-collection>
    </ejs-accumulationchart>
  </div>
</template>
<script>
import Vue from "vue";
import { AccumulationChartPlugin, PieSeries, AccumulationLegend, AccumulationTooltip, AccumulationDataLabel } from "@syncfusion/ej2-vue-charts"

Vue.use(AccumulationChartPlugin)

export default {
  props: {
    dataIncassoNelTempo: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      legendSettings: {
        visible: true,
      },
      datalabel: { visible: true, name: 'text' },
      tooltip:{ enable: true, format: '${point.x} : <b>${point.y}%</b>'},
      pointColorMapping: 'fill',
      title: 'Prodotti Maggiore Incasso(%)',
      seriesData: [
        { x: 'Jan', y: 3, fill: '#498fff', text:'January' }, { x: 'Feb', y: 3.5, fill: '#ffa060', text: 'February' },
        { x: 'Mar', y: 7, fill: '#ff68b6', text: 'March' }, { x: 'Apr', y: 13.5, fill: '#81e2a1', text: 'April' },
      ]
    };
  },
  provide: {
     accumulationchart: [PieSeries, AccumulationLegend, AccumulationTooltip, AccumulationDataLabel ]
  }
};
</script>
<style>

</style>