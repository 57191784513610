<template>
  <div id="container">
    <ejs-accumulationchart
      ref="pieInstance"
      class="chart"
      :theme="theme"
      style="display:block;height:100% !important; width:100% !important;"
      :legend-settings="legendSettings"
    >
      <e-accumulation-series-collection>
        <e-accumulation-series
          :data-source="seriesData"
          x-name="x"
          y-name="y"
          :data-label="dataLabel"
          :explode="true"
          name="Usage"
          explode-offset="10%"
          :explode-index="2"
        />
      </e-accumulation-series-collection>
    </ejs-accumulationchart>
  </div>
</template>
<script>
import Vue from 'vue'
import {
  AccumulationChartPlugin, PieSeries, AccumulationDataLabel, AccumulationTooltip,
} from '@syncfusion/ej2-vue-charts'

Vue.use(AccumulationChartPlugin)
// eslint-disable-next-line no-restricted-globals
let selectedTheme = location.hash.split('/')[1]
// selectedTheme = selectedTheme || 'Material'
selectedTheme = 'Bootstrap' || 'Material'

const theme = (selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)).replace(/-dark/i, 'Dark')

export default {
  data() {
    return {
      theme,
      seriesData: [
        { x: 'Desktop', y: 37, text: '60%' }, { x: 'Mobile', y: 17, text: '10%' },
        { x: 'Tablet', y: 19, text: '20%' },
      ],
      legendSettings: { visible: false },
      dataLabel: { visible: true, position: 'Inside', name: 'text' },
    }
  },
  provide: {
    accumulationchart: [PieSeries, AccumulationDataLabel, AccumulationTooltip],
  },
  mounted() {
    this.$refs.pieInstance.height = '100%'
    this.$refs.pieInstance.width = '100%'
  },
}
</script>
<style>
 .dashboard-seo .chart-content, .dashboard-seo #container, .dashboard-seo .chart.e-control.e-accumulationchart{
    height: 100%;
    width:100%;
  }
</style>
