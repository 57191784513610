<template>
  <div id="container">
    <ejs-accumulationchart
      ref="pieInstance1"
      class="chart"
      :theme="theme"
      style="display:block;height:100% !important,width:100% !important;"
      :legend-settings="legendSettings"
      :tooltip="tooltip"
    >
      <e-accumulation-series-collection>
        <e-accumulation-series
          :palettes="palettes"
          :data-source="seriesData"
          x-name="Device"
          y-name="Amount"
          inner-radius="40%"
          :data-label="dataLabel"
        />
      </e-accumulation-series-collection>
    </ejs-accumulationchart>
  </div>
</template>
<script>
import Vue from 'vue'
import {
  AccumulationChartPlugin, PieSeries, AccumulationDataLabel, AccumulationTooltip, Legend,
} from '@syncfusion/ej2-vue-charts'

Vue.use(AccumulationChartPlugin)
// eslint-disable-next-line no-restricted-globals
let selectedTheme = location.hash.split('/')[1]
selectedTheme = selectedTheme || 'Material'
const theme = (selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)).replace(/-dark/i, 'Dark')
export default {
  data() {
    return {
      theme,
      startAngle: 0,
      endAngle: 360,
      seriesData: [
        {
          UniqueId: 'T100003',
          DateTime: new Date(1488359820000),
          Category: 'Food',
          PaymentMode: 'Cash',
          TransactionType: 'Expense',
          Description: 'Confederate cush',
          Amount: '900',
          MonthShort: 'Mar',
          MonthFull: 'March, 2017',
          FormattedDate: '03/01/2017 08:53 PM',
          Device: 'Tablet',
        }, {
          UniqueId: 'T100004',
          DateTime: new Date(1491038220000),
          Category: 'Transportation',
          PaymentMode: 'Credit Card',
          TransactionType: 'Expense',
          Description: 'Public and other transportation',
          Amount: '1200',
          MonthShort: 'Apr',
          MonthFull: 'April, 2017',
          FormattedDate: '04/01/2017 10:44 AM',
          Device: 'Desktop',
        }, {
          UniqueId: 'T100005',
          DateTime: new Date(1493630220000),
          Category: 'Transportation',
          PaymentMode: 'Cash',
          TransactionType: 'Expense',
          Description: 'Public and other transportation',
          Amount: '600',
          MonthShort: 'May',
          MonthFull: 'May, 2017',
          FormattedDate: '05/01/2017 03:25 PM',
          Device: 'Mobile',
        },
      ],
      legendSettings: { visible: true },
      dataLabel: { visible: true, position: 'Inside', name: 'value' },
      tooltip: {
        // eslint-disable-next-line no-template-curly-in-string
        enable: true, header: '<b>${point.x}</b>', format: 'Composition: <b>${point.y}</b>',
      },
      palettes: ['#00bdae', '#357cd2', '#e56691'],
      // Animation for chart series
      animation: true,
    }
  },
  provide: {
    accumulationchart: [PieSeries, AccumulationDataLabel, AccumulationTooltip, Legend],
  },
  mounted() {
    this.$refs.pieInstance1.height = '80%'
    this.$refs.pieInstance1.width = '100%'
  },
  methods: {
  },
}
</script>
<style>
 .dashboard-seo .chart-content, .dashboard-seo #container, .dashboard-seo .chart.e-control.e-accumulationchart{
    height: 100%;
    width:100%;
  }
</style>
